import { errorMap } from "../constants/error-map";
import { ErrorKind } from "./error-kind";

/** Describes an application error. */
export class AppError {
    /** Error kind. */
    public kind: ErrorKind;

    /** Translated error message. */
    public message: string;

    /** Error code. */
    public code: string;

    /** Field name. */
    public fieldName: string;

    /** Complete list of errors. */
    public errors: AppError[];

    /**
     * App error Constructor.
     * @param defaultKind The constructor will try to detect the kind of the error. This default kind will be used if not found.
     * @param message Error message to display.
     * @param code Error code.
     */
    constructor(defaultKind: ErrorKind, message: string, code: string, errors: AppError[] = [], fieldName = "") {
        this.kind = this.getErrorKind(code, defaultKind);
        this.message = message;
        this.code = code;
        this.errors = errors;
        this.fieldName = fieldName;
    }

    private getErrorKind(errCode: string, defaultValue: ErrorKind = ErrorKind.Unknown): ErrorKind {
        // We cannot use String.equals here because in some case it is not already loaded.
        let info = errorMap.find(e => errCode == e.translationKey);
        return info != null ? info.kind : defaultValue;
    }

    /**
     * Parse error from the server API (private API).
     * @param error - Server response.
     */
    public static parseServerApiError(error: any): AppError {
        if (Object.prototype.hasOwnProperty.call(error, "error")
            && error.error != null
            && Object.prototype.hasOwnProperty.call(error.error, "Errors")
            && error.error.Errors != null
            && error.error.Errors.length > 0
            && Object.prototype.hasOwnProperty.call(error.error.Errors[0], "code")
            && Object.prototype.hasOwnProperty.call(error.error.Errors[0], "message")) {

            let errors: AppError[] = [];
            for (let err of error.error.Errors) {
                try {
                    errors.push(new AppError(ErrorKind.Unknown, err.message, err.code, [], err.fieldName))
                } catch (ex) {
                    console.warn(ex);
                }
            }

            return new AppError(ErrorKind.Unknown, error.error.Errors[0].message, error.error.Errors[0].code, errors, error.error.Errors[0].fieldName);
        }

        return null;
    }

    /**
     * 
     * Parse error from the API (public API).
     * @param error - Server response.
     */
    public static parsePublicApiError(error: any): AppError {
        if (Object.prototype.hasOwnProperty.call(error, "error")
            && error.error != null
            && Object.prototype.hasOwnProperty.call(error.error, "error")
            && error.error.error != null
            && Object.prototype.hasOwnProperty.call(error.error.error, "type")
            && Object.prototype.hasOwnProperty.call(error.error.error, "name")
            && Object.prototype.hasOwnProperty.call(error.error.error, "message")) {
            let err = error.error.error;

            if (String.equals(err.name, "ERR_ROOM_TEMPLATE_ACTIVE") && !location.pathname.includes("NotAccessible"))
                location.reload();

            return new AppError(ErrorKind.ApiError, err.message, err.name);
        }

        return null;
    }
}