import { ApiConverter } from "../../../interface/api-converter";

export abstract class ServiceApiConverter implements ApiConverter {
    public parse<T>(response: any): T {

        if (response != null && response.statusCode == 0) {
            return response.data;
        }

        if (response != null && response.Errors != null && response.Errors.length > 0) {
            let error = response.Errors[0];

            if (String.equals(error.Code, "Error_RoomTemplateActive") || String.equals(error.Code, "ERR_ROOMTEMPLATEACTIVE"))
                if (!location.pathname.includes("NotAccessible")) {
                    location.reload();
                    return null;
                }

            throw response;
        }

        return null;
    }

    abstract convert(source: any): any;
}

export class GenericConverter extends ServiceApiConverter {
    private converter: <T, U>(source: T) => U;

    public convert<T, U>(source: T): U {
        return this.converter<T, U>(source);
    }

    constructor(converter: any) {
        super();
        this.converter = converter;
    }
}

export class PrimitiveConverter extends ServiceApiConverter {
    public convert<T>(source: T): T {
        return source;
    }
}

export class EmptyConverter extends ServiceApiConverter {
    public convert<T, U>(source: T): U { // eslint-disable-line @typescript-eslint/no-unused-vars -- Must be ignored because it is the implementation of an abstract class.
        return null;
    }
}

export class BufferConverter {
    public parse<ArrayBuffer>(response: any): ArrayBuffer {
        return response;
    }

    public convert<T>(source: T): T {
        return source;
    }
}

/**
 * No conversion.
 * @deprecated - Used only for old component support.
 */
export class RawConverter {
    public parse<T>(response: any): T {
        return response;
    }

    public convert<T>(source: T): T {
        return source;
    }
}