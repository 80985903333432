import { ErrorInfo } from "../core/error-info";
import { ErrorKind } from "../core/error-kind";
import { TranslationKey } from "../translation/translation-key.g";

export const errorMap = [
    new ErrorInfo("EDS_DISABLED", ErrorKind.EdsDisabled, TranslationKey.EdsDisabled),
    new ErrorInfo("ERR_DOCUSIGN", ErrorKind.DocusignError, TranslationKey.DocusignErrorFileGeneric),
    new ErrorInfo("ERR_DOCUSIGN_ADMIN_REQUIRED", ErrorKind.DocusignAdminRequired, TranslationKey.DocusignErrorAdminRequired),
    new ErrorInfo("ERR_DOCUSIGN_USER_CONSENT_REQUIRED", ErrorKind.DocusignUserConsentRequired, TranslationKey.DocusignErrorUserConsentRequired),
    new ErrorInfo("ERR_DOCUSIGN_USER_CONSENT_REQUIRED_OR_PROVIDER_CONFIG_INVALID", ErrorKind.DocusignUserConsentRequiredOrInvalidConfig, TranslationKey.DocusignErrorUserConsentOrConfigRequired),
    new ErrorInfo("ERR_DOCUSIGN_NO_DOCUMENT_RECEIVED", ErrorKind.DocusignError, TranslationKey.DocusignErrorEmptyFile),
    new ErrorInfo("EDS_REQUEST_RIGHT_REQUIRED", ErrorKind.EdsSignatureRightRequired, TranslationKey.EdsRequestRightRequired)
];