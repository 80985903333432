/** Describes an Error kind. */
export enum ErrorKind {
    ApiError,
    Canceled,
    Debug,

    EdsDisabled,
    EdsSignatureRightRequired,

    // Docusign
    DocusignError,
    DocusignAdminRequired,
    DocusignUserConsentRequired,
    DocusignUserConsentRequiredOrInvalidConfig,

    EdsNotConfigured,
    ProviderError,
    WindowClosed,
    WindowNotOpened,

    FrontError,
    ExceptionThrown,
    Validation,

    Unknown,
}