import { TranslationKey } from "../translation/translation-key.g";
import { ErrorKind } from "./error-kind";

export class ErrorInfo {
    public apiError: string;
    public kind: ErrorKind;
    public translationKey: TranslationKey;

    constructor(apiError: string, kind: ErrorKind, translationKey: TranslationKey) {
        this.apiError = apiError;
        this.kind = kind;
        this.translationKey = translationKey;
    }
}